enum EventType {
  GA = 'gaEvent',
  PAGE_VIEW = 'pageview',
  CUSTOM_CRITEO = 'fireCriteoTag',
}

enum EventCategory {
  INDEX = 'index',
  ACCOUNT_LOGIN = 'account/login',
  SEARCH_HISTORY = 'searchHistory',
  TOOLTIP = 'tooltip',
  ROUNDTRIP = 'roundtrip',
  NAVIGATION = 'navigation',
  DRIVERAGE = 'driverage',
  CONFIENZA = 'confienza',
  FAQ = 'faq',
  SEARCH_BUTTON_OFFERBOX = 'search_button_offerbox',
  CUSTOMER_REVIEW = 'customer_review',
  APP_ICON = 'app_icon',
  SOCIAL_MEDIA_ICON = 'social_media_icon',
  GENERAL_FOOTER_LINKS = 'general_footer_links',
  NEWSLETTER_REGISTRATION = 'newsletter/registration',
  ALL = 'all',
  QR_CODE_BANNER = 'qr_code_banner',
  INFO_BANNER = 'info_banner',
  WEB_VITALS = 'web_vitals',
  STATIONS_MAP = 'stations_map',
  PRICE_GRAPH = 'price_graph',
  OFFERS_SLIDER = 'offers_slider',
  MARKET_PICKER = 'market_picker',
  SEARCH = 'single_entry_search',
  MARKETING_SLIDER = 'marketing_slider',
}

enum EventAction {
  CLICK = 'click',
  CLICK_PHONE_TOOLTIP_VOUCHER_LINK = 'clickPhoneTooltipVoucherLink',
  HOVER = 'hover',
  LOGIN_LOGOUT = 'login_logout',
  LOGIN_MENU = 'login_menu',
  LOGIN_MENU_CREATE_ACCOUNT = 'login_menu_create_account',
  LOGIN_MENU_FORGOTPWD = 'login_menu_forgotpwd',
  LOGIN_MENU_LOGIN = 'login_menu_login',
  LOGIN_MENU_MYBOOKINGS = 'login_menu_mybookings',
  LOGIN_MENU_MYDATA = 'login_menu_mydata',
  LOGIN_MENU_MYPAYMENT = 'login_menu_mypayment',
  MASTERCARD_CARIGAMI_CLICKED = 'mastercard_carigami_clicked',
  MASTERCARD_CARIGAMI_SEEN = 'mastercard_carigami_seen',
  ONEWAY_SELECT = 'oneway_select',
  SEARCHFORM_MORE_OPTIONS = 'searchform_more_options',
  SEARCHFORM_MORE_OPTIONS_FILTER_CLICK = 'searchform_more_options_filter_click',
  SELECT = 'select',
  SLIDE_OFFER_BOXES_LP = 'slide_offer_boxes_lp',
  CLICK_OFFER_BOXES_LP = 'click_offer_boxes_lp',
  DRAG_OFFER_BOXES_LP = 'drag_offer_boxes_lp',
  MOUSEWHEEL_OFFER_BOXES_LP = 'mousewheel_offer_boxes_lp',
  LANGUAGE_CHANGE = 'language_change',
  REVIEWS_CAROUSEL_SLIDE = 'reviews_carousel_slide',
}

enum EventLabel {
  LOGGED = 'logged',
  UNLOGGED = 'unlogged',
  OPEN = 'open',
  CLOSED = 'closed',
}

type EventProps = {
  event: EventType;
  eventCategory: EventCategory;
  eventAction: EventAction | string;
  eventLabel: EventLabel | string | boolean;
  eventTarget?: string;
  eventType?: string;
  eventValue?: number;
  nonInteraction?: boolean;
  transport?: 'beacon';
};

enum CriteoPageType {
  Homepage = 'Homepage',
}

enum CriteoSiteType {
  desktop = 'd',
}

type CriteoEventProps = {
  event: EventType.CUSTOM_CRITEO;
  criteo_tag_params: {
    CriteoPageType: CriteoPageType;
    CriteoSiteType: CriteoSiteType;
  };
};

type LoginProps = {
  isLoggedIn: boolean;
};

type SliderCardProps = {
  title: string;
};

const environments = {
  live: 'live',
  dev: 'dev',
} as const;

type EnvironmentType = keyof typeof environments | string;

type TrackingProps = {
  virtPath: string;
  /** Format: `/Country/State` | `/Country/City` */
  landingpageKategorie: string;
  websitebereich:
    | 'Startseite, Mietwagen-Suche'
    | 'Landingpage, Mietwagen-Suche'
    | 'Content-Seite';
  /** Format: `d/sea/$location` | `d/seo/$keyword`. The `d` prefix was originally used to identify **d**esktop and **m**obile. */
  englishPageName: string;
  environment: EnvironmentType;
  /** Either `bm` for all internal brands (incl. CG and FLOYT) or an actual affiliate name. */
  affiliateName: 'bm' | string;
  SPA: boolean;
  nextjs: boolean;
  twa: boolean;
  hasTduid: boolean;
  landingPageType: 'index' | 'seo';
  blockUserCentrics: boolean;
  anonymizeIP: boolean;
  locale: string;
  language: string;
  market: string;
};

export {
  CriteoPageType,
  CriteoSiteType,
  EventType,
  EventCategory,
  EventAction,
  EventLabel,
};
export type {
  CriteoEventProps,
  EventProps,
  LoginProps,
  TrackingProps,
  EnvironmentType,
  SliderCardProps,
};
