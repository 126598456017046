import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';
import { Ui } from '@silvertours/front-shared';
import Checked from '@engineering/icons/checked';
import ArrowDown from '@engineering/icons/arrow-down';
import ArrowUp from '@engineering/icons/arrow-up';
import { NewsletterSuccessIcon } from './NewsletterSuccessIcon';

const Card = styled(Ui.Card)`
  padding: 2rem 1rem 1.5rem;
  position: relative;
`;

const CardWrapper = styled.div`
  margin-top: 1rem;
  ${({ theme }) => theme.mq('md')} {
    margin-top: 4rem;
  }
  ${({ theme }) => theme.mq('lg')} {
    margin-top: -5rem;
  }
  ${({ theme }) => theme.mq('xl')} {
    margin-top: -6rem;
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    display: flex;
    gap: 1rem;
  }
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 1rem;
`;

const SuccessIcon = styled(NewsletterSuccessIcon)`
  /* stylelint-disable-next-line no-empty-source */
`;

const SuccessText = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Headline = styled.div`
  margin: 0 0 1rem;
  font-weight: bold;
  font-size: 1rem;
`;

const LegalText = styled.p<{ $textVisible: boolean; $reduceMargin: boolean }>`
  position: relative;
  margin: ${({ $reduceMargin }) => ($reduceMargin ? '1rem 0 0' : '1rem 0')};
  color: ${({ theme }) => theme.color.grey01};
  font-size: 0.875rem;
  line-height: 1.5;
  max-height: ${({ $textVisible }) =>
    $textVisible ? '' : 'calc(3 * 1.5 * 0.875rem)'};
  max-height: ${({ $textVisible }) => ($textVisible ? '' : '3lh')};
  overflow: hidden;

  ${({ $textVisible }) =>
    !$textVisible &&
    css`
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(
          to top,
          rgb(255 255 255 / 100%) 1%,
          rgb(255 255 255 / 0%) 50%
        );
        pointer-events: none;
      }
    `};

  a {
    color: ${({ theme }) => theme.color.brand};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => shade(0.271, theme.color.brand!)};
      text-decoration: underline;
    }

    &:focus-visible {
      border-radius: 0.5rem;
      outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
      outline-offset: 0.25rem;
      text-decoration: underline;
    }

    &:active {
      color: ${({ theme }) => shade(0.271, theme?.color.brand!)};
      text-decoration: underline;
      text-underline-offset: 0.25rem;
    }
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NewsletterPictogramWrapper = styled.div`
  margin-bottom: -0.9rem;
  margin-left: 1.5rem;
`;

const NewsletterContainer = styled.article`
  position: relative;
  display: grid;
  ${({ theme }) => theme.mq('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[50]};
  margin-bottom: -1rem;
`;

const BadgeWrapper = styled.div`
  position: relative;
  font-size: 1rem;
  right: 0;
  top: -1.5rem;
  ${({ theme }) => theme.mq('md')} {
    right: 1.5rem;
    top: -3rem;
  }
`;

const NewsletterText = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
`;

const List = styled.ul`
  color: ${({ theme }) => theme.color.text};
`;

const ListItem = styled.li`
  font-size: 1.063rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 1.6rem 0;
`;

const StyledChecked = styled(Checked)`
  fill: ${({ theme }) => theme.color.text};
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  padding-right: 0.5rem;
`;

const StyledArrowDown = styled(ArrowDown)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1rem;
  height: 1rem;
`;

const StyledArrowUp = styled(ArrowUp)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1rem;
  height: 1rem;
`;

const ToggleLink = styled(Ui.TextLink)`
  margin-block: 0.375rem ${({ theme }) => theme.spacing[40]};
  font-size: 1.125rem;
  text-decoration: none;
`;

export {
  Container,
  SuccessContainer,
  Form,
  Headline,
  LegalText,
  Card,
  NewsletterPictogramWrapper,
  NewsletterContainer,
  BadgeWrapper,
  NewsletterText,
  List,
  ListItem,
  StyledChecked,
  ToggleLink,
  StyledArrowDown,
  StyledArrowUp,
  CardWrapper,
  SuccessText,
  SuccessIcon,
};
