import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const Container = styled(Ui.Card)`
  display: flex;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;

  ${({ theme }) => theme.mq('sm')} {
    font-family: ${({ theme }) => theme.fontFamily.alternate};
    font-weight: 700;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[20]};
    ${({ theme }) => theme.mq('sm')} {
      padding: ${({ theme }) => theme.spacing[40]};
    }

    a {
      color: ${({ theme }) => theme.color.brand};
      text-decoration: none;
    }
  }
`;

const LinkOnlyContainer = styled(Ui.Card)`
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  background-color: ${({ theme }) => theme.color.grey05};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing[40]};

  a {
    color: ${({ theme }) => theme.color.brand};
    text-decoration: none;
  }
`;

export { Container, LinkOnlyContainer };
