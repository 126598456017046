import { useTranslations } from 'next-intl';
import { Heading3 } from '@silvertours/front-shared';

import {
  SuccessContainer,
  SuccessText,
  SuccessIcon,
} from './NewsletterRegistration.styles';

type NewsletterSignupSuccessProps = {
  showTitle?: boolean;
};

const NewsletterSignupSuccess = ({
  showTitle,
}: NewsletterSignupSuccessProps) => {
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  return (
    <SuccessContainer>
      <SuccessIcon />
      {showTitle && <Heading3>{t('success.title')}</Heading3>}
      <SuccessText>{t('success.text')}</SuccessText>
      <p>{t('success.subtext')}</p>
    </SuccessContainer>
  );
};

export { NewsletterSignupSuccess };
