import styled from '@emotion/styled';
import { CarouselProps } from 'frontend/entities/src/Carousel/Carousel';

const Container = styled.div<CarouselProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[20]};
  padding-left: 1rem;
  padding-right: 1rem;

  & > * {
    width: 326px;
  }

  ${({ theme }) => theme.mq('sm')} {
    gap: ${({ theme }) => theme.spacing[40]};

    & > * {
      width: 392px;
    }

    padding-left: 2rem;
    padding-right: 2rem;
  }

  ${({ theme }) => theme.mq('xl')} {
    gap: ${({ theme }) => theme.spacing[50]};
    padding-left: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
    padding-right: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
  }

  ${({ theme }) => theme.mq('xxl')} {
    padding-left: clamp(2rem, 350px, 22rem);
    padding-right: clamp(2rem, 350px, 22rem);
  }
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  color: #222;
  background-color: #f3f3f3;

  ${({ theme }) => theme.mq('md')} {
    padding-bottom: 4.5rem;
  }

  nav {
    margin-right: ${({ theme }) => theme.spacing[40]};
  }
`;

export { Container, Wrapper };
